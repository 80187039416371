












import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { generatePageHead } from '~/services/seo'

export default Vue.extend({
  name: 'PagesAuthSignIn',
  head(): MetaInfo {
    return generatePageHead(
      this.$t('PAGE_HEAD_TITLE_AUTH_SIGN_IN') as string,
      this.$t('PAGE_HEAD_META_DESCRIPTION_AUTH_SIGN_IN') as string
    )
  },
  computed: {
    email() {
      return this.$route.query.email
    },
    socialAuthCode() {
      return this.$route.query.socialAuthCode
    },
    socialAuthError() {
      const {
        socialAuthError,
        socialAuthErrorReason,
        socialAuthErrorDescription,
      } = this.$route.query
      if (socialAuthError)
        return {
          error: socialAuthError,
          reason: socialAuthErrorReason,
          description: socialAuthErrorDescription,
        }
      else return undefined
    },
  },
})
