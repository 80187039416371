




import Vue, { PropType } from 'vue'
import { Look as FooterLook } from '~/styleguide/patterns/Footer.vue'
import {
  selectFooterLook,
  selectNavbarTheme,
  selectNavbarPosition,
} from '~/layouts/default.vue'

export default Vue.extend({
  name: 'CommonPageConfig',
  props: {
    navbarTheme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'light' as 'light' | 'dark',
    },
    navbarPosition: {
      type: String as PropType<'fixed' | 'absolute'>,
      default: 'fixed' as 'fixed' | 'absolute',
    },
    footerLook: {
      type: String as PropType<FooterLook>,
      default: 'dark-neutral' as FooterLook,
    },
  },
  mounted() {
    selectNavbarTheme(this.navbarTheme)
    selectNavbarPosition(this.navbarPosition)
    selectFooterLook(this.footerLook)
  },
})
